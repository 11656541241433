.news {
  .news__item {
    box-sizing: border-box;
    padding: 0 10px;
  
    @include media-above-mobile {
      max-width: 536*$pxToRem;
      padding: 46*$pxToRem 0 125*$pxToRem 0;
  
      & > *:not(img) {
        max-width: 420*$pxToRem;
      }
    }
  
    h3 {
      margin-bottom: 0;
  
      & + p {
        margin-top: 0;
      }
    }
  }
}

.section-memberships + .news {
  margin-top: 145*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 145*$pxToRem;
  }

  @include media-mobile-only {
    margin-top: 0;
  }
}

.section-team-member + .news {
  margin-top: 25*$pxToVw;

  @include media-lg-monitor-only {
    margin-top: 25*$pxToRem;
  }

  @include media-mobile-only {
    margin-top: 0;
  }
}
